import { AppManifest } from '@wix/app-manifest-builder';
import {
  ConnectedComponentConfig,
  ControllerStateStageData,
  GfppConfig,
  GfppDesktopConfig,
  WidgetStateStageData,
} from '@wix/platform-editor-sdk';
import pick from 'lodash/pick';

const TRACKING_PAGE_CONTROLLER_ID =
  'e329a4ab-e1b2-4268-9cc6-ac6f77ec6767-euxz6';

export const setTrackerGfpp = (appManifest: AppManifest) => {
  setTrackerMobileGfpp(appManifest);
};

const setTrackerMobileGfpp = (appManifest: AppManifest) => {
  const [trackingPageStageDataDefault] = getControllerWidgets(appManifest, [
    TRACKING_PAGE_CONTROLLER_ID,
  ]);
  const trackingPageComponents = getChildComponents(
    trackingPageStageDataDefault,
    ['tracker2', 'DeliveryDetailsBox', 'HeaderTracking'],
  );

  const stageDataDefaultArr = [
    trackingPageStageDataDefault,
    ...trackingPageComponents,
  ].filter((widget): widget is WidgetStateStageData => !!widget);

  addMobileDesign(stageDataDefaultArr);

  stageDataDefaultArr.forEach((element) => {
    addMobileGfpp(element.gfpp as GfppConfig, ['mainAction1', 'iconButtons']);
  });
};

const addMobileDesign = (
  widgetStateData: (WidgetStateStageData | ConnectedComponentConfig)[],
) => {
  widgetStateData.forEach((widget) => {
    widget.gfpp && addMobileGfpp(widget.gfpp, ['widgetDesign']);
  });
};

const addMobileGfpp = (
  gfppConfig: GfppConfig,
  gfpps: (keyof GfppDesktopConfig)[],
) => {
  if (gfppConfig.desktop) {
    const pickedGfpp = pick(gfppConfig.desktop, gfpps);
    if (gfppConfig.mobile) {
      // @ts-expect-error
      gfppConfig.mobile = { ...gfppConfig.mobile, ...pickedGfpp };
    } else {
      // @ts-expect-error
      gfppConfig.mobile = pickedGfpp;
    }
  }
};

const getChildComponents = (
  widgetState: WidgetStateStageData | ControllerStateStageData,
  elementIds: string[],
) => {
  return elementIds.map(
    (elementId) => widgetState.connections?.[elementId] ?? {},
  );
};

const getControllerWidgets = (
  appManifest: AppManifest,
  widgetControllerIds: string[],
) => {
  return widgetControllerIds.map((widgetId) =>
    getControllerWidgetById(appManifest, widgetId),
  );
};

const getControllerWidgetById = (appManifest: AppManifest, id: string) => {
  return appManifest.controllersStageData![id].default;
};
