import { EditorReadyFn, FlowAPI } from '@wix/yoshi-flow-editor';
import { editorReadyFn } from './editor-app/editorReady';
import { AppExposedApis, EditorSDK } from '@wix/platform-editor-sdk';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { setTrackerGfpp } from './utils/gfpp';
import { Specs } from './constants/specs';

const OLO_APP_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60';

export const editorReady: EditorReadyFn = editorReadyFn;

export const getAppManifest = async (
  options,
  _,
  __,
  flowAPI: FlowAPI,
): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  const appManifest = appManifestBuilder.withJsonManifest(baseManifest).build();

  if (flowAPI.experiments.enabled(Specs.DESIGN_PANELS)) {
    setTrackerGfpp(appManifest);
  }

  return appManifest;
};

export const exports = (editorSDK: EditorSDK): AppExposedApis => ({
  editor: {
    removeAppCompleted: ({ appDefinitionId }) => {
      if (appDefinitionId === OLO_APP_ID) {
        editorSDK.application.uninstall('', { openConfirmation: false });
      }
    },
  },
});
