import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { GfppActions, TRACKER_HELP_ID } from '../../constants/gfpp';
import {
  ConnectedComponentsBuilder,
  WidgetBuilder,
} from '@wix/app-manifest-builder';
import {
  globalDesignTabs,
  globalDesignTitleKey,
  headingDesignTabs,
  headingDesignTitleKey,
  methodDesignTabs,
  methodDesignTitleKey,
  statusDesignTabs,
  statusDesignTitleKey,
} from './designTab/constants';
import { TabConfig } from './designTab/types';
import { TFunction } from '@wix/yoshi-flow-editor';
import { Specs } from '../../constants/specs';

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _,
  flowAPI,
) => {
  const t = flowAPI.translations.t as TFunction;
  widgetBuilder.behavior().set({ removable: false });
  const experiments = flowAPI.experiments;
  const isDesignPanelEnable = experiments.enabled(Specs.DESIGN_PANELS);

  if (isDesignPanelEnable) {
    widgetBuilder
      .set({
        displayName: t('tracker.page.editor.widget-name.root'),
        nickname: 'TrackingPageWidget',
      })
      .gfpp()
      .set('mainAction1', {
        label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
        actionId: GfppActions.KANBAN_ACTION_ID,
      })
      .set('design', { behavior: 'DEFAULT' })
      .set('help', { id: TRACKER_HELP_ID });

    configureDesignPanel(
      widgetBuilder,
      t,
      globalDesignTitleKey,
      globalDesignTabs,
    );
  } else {
    widgetBuilder
      .set({
        displayName: 'Order tracking',
        nickname: 'TrackingPageWidget',
      })
      .gfpp()
      .set('help', { id: TRACKER_HELP_ID })
      .set('mainAction1', { behavior: 'DEFAULT' });
  }

  widgetBuilder.configureConnectedComponents(
    'tracker2',
    (connectedComponentsBuilder) => {
      if (isDesignPanelEnable) {
        connectedComponentsBuilder
          .set({
            displayName: t('tracker.page.editor.widget-name.status-container'),
          })
          .gfpp()
          .set('mainAction1', {
            label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
            actionId: GfppActions.KANBAN_ACTION_ID,
          })
          .set('design', { behavior: 'DEFAULT' })
          .set('help', { id: TRACKER_HELP_ID });
        configureDesignPanel(
          connectedComponentsBuilder,
          t,
          statusDesignTitleKey,
          statusDesignTabs,
        );
      } else {
        connectedComponentsBuilder
          .gfpp()
          .set('mainAction1', { behavior: 'DEFAULT' })
          .set('help', { id: TRACKER_HELP_ID });
      }
    },
  );

  if (isDesignPanelEnable) {
    widgetBuilder.configureConnectedComponents(
      'DeliveryDetailsBox',
      (connectedComponentsBuilder) => {
        connectedComponentsBuilder.set({
          displayName: t(
            'tracker.page.editor.widget-name.fulfillment-details-container',
          ),
        });
        connectedComponentsBuilder
          .gfpp()
          .set('design', { behavior: 'DEFAULT' })
          .set('mainAction1', {
            label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
            actionId: GfppActions.KANBAN_ACTION_ID,
          })
          .set('help', { id: TRACKER_HELP_ID });

        configureDesignPanel(
          connectedComponentsBuilder,
          t,
          methodDesignTitleKey,
          methodDesignTabs,
        );
      },
    );

    widgetBuilder.configureConnectedComponents(
      'HeaderTracking',
      (connectedComponentsBuilder) => {
        connectedComponentsBuilder.set({
          displayName: t('tracker.page.editor.widget-name.page-header'),
        });
        connectedComponentsBuilder
          .gfpp()
          .set('design', { behavior: 'DEFAULT' })
          .set('mainAction1', {
            label: t('tracker.page.editor.gfpps.main-action-1.open-prep-board'),
            actionId: GfppActions.KANBAN_ACTION_ID,
          })
          .set('help', { id: TRACKER_HELP_ID });

        configureDesignPanel(
          connectedComponentsBuilder,
          t,
          headingDesignTitleKey,
          headingDesignTabs,
        );
      },
    );
  }
};

const configureDesignPanel = (
  widgetBuilder: WidgetBuilder | ConnectedComponentsBuilder,
  t: TFunction,
  titleKey: string,
  designTabs: TabConfig[],
) => {
  widgetBuilder.configureWidgetDesign((widgetDesignBuilder) => {
    widgetDesignBuilder.set({
      title: t(titleKey),
    });
    const widgetDesignTabsBuilder = widgetDesignBuilder.tabs();

    designTabs.forEach(({ labelKey, selectors }) => {
      widgetDesignTabsBuilder.addTab((tabBuilder) => {
        tabBuilder
          .set({
            label: t(labelKey),
            dependents: selectors,
          })
          .groups()
          .set({
            roles: selectors,
          });
      });
    });
  });
};
